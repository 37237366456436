import styled from "@emotion/styled"
import { mq } from "../../util/theme"

import Carat from "../../images/select-carat.svg"

export const MapWrap = styled.section`
  height: 350px;
  width: 100%;
  border: 2px solid ${props => props.theme.colors.location};
`

export const ListingWrap = styled.section`
  margin: 1rem 0 0;
`

export const LocationsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  @media print{
    list-style: disk;
  }
`

export const LocationsItem = styled.li`
  margin: 0;
  padding: 1.5rem 0;
  border-bottom: 1px solid ${props => props.theme.colors.location};
  &:last-of-type {
    border: none;
  }
`

export const LocationCountry = styled.h3`
  margin: 0 0 0.5rem;
  color: ${props => props.theme.colors.copy};
  font-size: ${props => props.theme.fontSizes.reg};
  font-family: ${props => props.theme.fonts.heading};
  font-weight: ${props => props.theme.weights.bold};
`

export const TrialLocation = styled.h4`
  margin: 0 0 1rem;
  color: ${props => props.theme.colors.copy};
  font-size: ${props => props.theme.fontSizes.sm};
  font-family: ${props => props.theme.fonts.roboto};
  font-weight: ${props => props.theme.weights.light};
  font-style: italic;
`

export const TrialContact = styled.div`
  p {
    margin: 0 0 0.25rem !important;
    color: ${props => props.theme.colors.copy};
    font-size: ${props => props.theme.fontSizes.sm};
    font-family: ${props => props.theme.fonts.roboto};
    font-weight: ${props => props.theme.weights.reg};
    line-height: 1.25;
    a {
      color: ${props => props.theme.colors.copy};
      display: inline-block;
      &:hover,
      &:focus,
      &:active {
        color: ${props => props.theme.colors.blue};
      }
    }
  }
`

export const Pin = styled.div`
  border: 1px solid ${props => props.theme.colors.white};
  border-radius: 50%;
  height: 15px;
  width: 15px;
  background: ${props =>
    props.show === true ? props.theme.colors.blue : props.theme.colors.teal};
  cursor: pointer;
  z-index: 10;
`

export const InfoWindow = styled.div`
  position: relative;
  bottom: 180px;
  left: -97px;
  width: 225px;
  height: 150px;
  background: ${props => props.theme.colors.white}!important;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
  padding: 0.75rem 1rem;
  z-index: 100;
  &:after {
    content: "";
    background: linear-gradient(
      45deg,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 51%,
      rgba(255, 255, 255, 0) 100%
    );
    height: 1rem;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    bottom: -1rem;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 1rem;
  }
  h4 {
    font-size: ${props => props.theme.fontSizes.tiny};
    font-family: ${props => props.theme.fonts.roboto};
    font-weight: ${props => props.theme.weights.bold};
    margin: 0 0 0.5rem;
  }
  p {
    font-size: ${props => props.theme.fontSizes.tiny};
    font-family: ${props => props.theme.fonts.roboto};
    font-weight: ${props => props.theme.weights.reg};
    margin: 0;
    a {
      color: ${props => props.theme.colors.copy};
      display: block;
      &:hover,
      &:focus,
      &:active {
        color: ${props => props.theme.colors.blue};
      }
    }
  }
`

export const ControlsWrap = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 2.5rem 0 1.5rem;
  ${mq[1]} {
    flex-direction: row;
  }
`

export const FormInput = styled.input`
  border: 1px solid ${props => props.theme.colors.select};
  //border-radius: 4px;
  color: ${props => props.theme.colors.copy};
  font-size: ${props => props.theme.fontSizes.reg};
  font-family: ${props => props.theme.fonts.roboto};
  background: ${props => props.theme.colors.white};
  display: block;
  width: 100%;
  padding: 0.6rem 1rem;
  margin-bottom: 0.75rem;
  ${mq[1]} {
    max-width: 145px;
    margin: 0;
  }
  &:focus {
    outline-color: ${props => props.theme.colors.teal};
  }
  &.is-radio {
    margin-bottom: 0;
  }
  &::placeholder {
    font-size: ${props => props.theme.fontSizes.sm};
    opacity: 0.5;
  }
`

export const FormSelect = styled.select`
  border: 1px solid ${props => props.theme.colors.select};
  border-radius: 4px;
  color: ${props => props.theme.colors.copy};
  font-size: ${props => props.theme.fontSizes.sm};
  font-family: ${props => props.theme.fonts.roboto};
  background: url(${Carat}) no-repeat 95% / contain;
  background-size: 0.75rem 0.6rem;
  display: block;
  width: 100%;
  //max-width: 145px;
  padding: 0.75rem 1rem;
  appearance: none;
  margin-bottom: 0.75rem;
  ${mq[1]} {
    max-width: 145px;
    margin: 0;
  }
`

export const Btn = styled.a`
  background: ${props => props.theme.colors.robins};
  border: 1px solid ${props => props.theme.colors.robins};
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSizes.reg};
  font-family: ${props => props.theme.fonts.robCondensed};
  font-weight: ${props => props.theme.weights.semi};
  letter-spacing: ${props => props.theme.leading.mini};
  justify-content: center;
  text-transform: uppercase;
  text-decoration: none;
  //border-radius: 4px;
  padding: 0.65rem 0.75rem;
  max-width: 135px;
  width: fit-content;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0;
  transition: all 0.2s ease-in-out;
  appearance: none;
  position: relative;
  white-space: nowrap;
  &.long{
    max-width: 300px;
  }
  &:before,
  &:after {
    transition: all 0.2s ease-in-out;
  }
  &:hover,
  &:active,
  &:focus {
    background: ${props => props.theme.colors.blue};
    border: 1px solid ${props => props.theme.colors.blue};
    color: ${props => props.theme.colors.white};
  }
  &:disabled {
    cursor: not-allowed;
    background: ${props => props.theme.colors.gray};
    border: 1px solid ${props => props.theme.colors.gray};
  }
  &.is-disabled {
    cursor: not-allowed;
    pointer-events: none;
    background: ${props => props.theme.colors.gray};
    border: 1px solid ${props => props.theme.colors.gray};
  }
  &.is-hidden {
    display: none;
  }
  &.big-pad {
    padding: 0.75rem 2rem;
    &:active,
    &:focus,
    &:hover {
      background: ${props => props.theme.colors.blue};
      border: 1px solid ${props => props.theme.colors.blue};
      color: ${props => props.theme.colors.white};
      &:disabled {
        cursor: not-allowed;
        background: ${props => props.theme.colors.gray};
        border: 1px solid ${props => props.theme.colors.gray};
      }
      &.is-disabled {
        cursor: not-allowed;
        pointer-events: none;
        background: ${props => props.theme.colors.gray};
        border: 1px solid ${props => props.theme.colors.gray};
      }
    }
  }
`
