import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { mq, theme } from "../../util/theme"

export const SidebarAside = styled.aside`
  ${mq[2]} {
    position: sticky;
    top: 2rem;
    z-index: 1;
    height: 100vh;
    margin-right: -2rem;
  }
  &.mobile{
    position: static;
    ${mq[2]} {
      height: 0;
    }
  }
  &.print{
    @media print{
      display: block;
      position: static;
      ${mq[2]} {
        position: static;
        height: auto;
      }
      &.mobile{
        position: static;
        ${mq[2]} {
          height: 0;
        }
      }
    }
  }
  @media print{
    display: none;
  }
`

export const SidebarInfo = styled.section`
  max-width: 320px;
  width: 100%;
  margin-top: 4rem;
  background: ${props => props.theme.colors.gray};
  padding: 2rem 2.5rem 2rem 1.5rem;
  display: none;
  ${mq[2]} {
    display: block;
  }

  &.mobile{
    max-width: 1000px;
    display: block;
    ${mq[2]} {
      display: none;
    }
  }

  &.print{
    @media print{
      margin-top: .5rem;
      max-width: none;
      display: block;
    }
  }
`

export const SidebarHeader = styled.h3`
  color: ${props => props.theme.colors.copy};
  font-size: ${props => props.theme.fontSizes.h3};
  font-family: ${props => props.theme.fonts.heading};
  font-weight: ${props => props.theme.weights.bold};
  line-height: 1.5;
  margin: 0;
`

export const SidebarCopy = styled.div`
  color: ${props => props.theme.colors.copy};
  font-size: ${props => props.theme.fontSizes.sm};
  font-family: ${props => props.theme.fonts.copy};
  font-weight: ${props => props.theme.weights.reg};
  line-height: 1.5;
  margin: 0 0 1.5rem;
  &.is-last {
    margin: 0;
  }
  a {
    color: ${props => props.theme.colors.copy};
    text-decoration: underline;
    // white-space: nowrap;
    &:hover {
      color: ${props => props.theme.colors.blue};
    }
  }
`

export const SidebarLink = styled.a`
  color: ${props => props.theme.colors.white};
  &:hover {
    color: ${props => props.theme.colors.yellow};
  }
`

export const OutlineBtn = styled.a`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSizes.h3};
  font-family: ${props => props.theme.fonts.copy};
  font-weight: ${props => props.theme.weights.bold};
  text-align: center;
  text-transform: uppercase;
  border: 2px solid ${props => props.theme.colors.white};
  border-radius: 5px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  max-width: 230px;
  width: 100%;
  padding: 1rem 0;
  margin: 1rem 0;
  display: none;
  ${mq[2]} {
    display: block;
  }
  &:hover {
    background: ${props => props.theme.colors.gold};
    color: ${props => props.theme.colors.white};
    border: 2px solid ${props => props.theme.colors.gold};
  }
`

export const ShareCopy = styled.p`
  display: none;
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.fontSizes.h3};
  font-family: ${props => props.theme.fonts.heading};
  font-weight: ${props => props.theme.weights.bold};
  line-height: 1.5;
  margin: 1.5rem 0 0.5rem;
  ${mq[2]} {
    display: block;
  }
`

export const ShareBtns = styled.section`
  display: none;
  justify-content: space-between;
  align-items: center;
  max-width: 140px;
  ${mq[2]} {
    display: flex;
    margin-top: 1rem;
  }
`

export const shareIcon = css`
  display: block;
  max-width: 35px;
  width: 100%;
  &:hover {
    g {
      fill: ${theme.colors.blue};
    }
  }
`