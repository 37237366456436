import React, { Fragment } from "react"
import PropTypes from "prop-types"
import isEmpty from "lodash.isempty"
import fetch from 'cross-fetch'
import Geocode from "react-geocode"
import { getUserLocale } from 'get-user-locale';
import _ from "lodash"

import GoogleMap from "./googleMap"

import {
  Btn,
  FormInput,
  FormSelect,
  MapWrap,
  ControlsWrap,
  ListingWrap,
  LocationsList,
  LocationsItem,
  LocationCountry,
  TrialLocation,
  TrialContact,
  InfoWindow,
  Pin,
} from "./styles"

import { PrintWrap } from "../styled"

const MAP_CENTER = [39.8097343, -98.5556199]
//const MAP_CENTER = [40.3676752, -27]

// Marker component
const Marker = ({ show, place }) => {
  return (
    <Fragment>
      <Pin show={show} />
      {show && (
        <InfoWindow place={place}>
          <h4>{place.name}</h4>
          <p>{place.address1}</p>
          <p>{place.address2}</p>
          {place.country !== "United States" && place.country !== "Canada" && place.country !== "Australia"? (
            <p>
              {place.city}, {place.postal}
            </p>
          ) : (
            <Fragment>
              <p>
                {place.city}, {place.state}
              </p>
              <p>{place.postal}</p>
            </Fragment>
          )}
           <p>{place.country}</p>
          {typeof(place.contact_name) !== "undefined" ? (
            <Fragment>
              <br />
              <p>{place.contact_name}</p>
            </Fragment>
          ) : (
            ""
          )}
          {typeof(place.contact_email) !== "undefined" ? (
            <p>
              <a href={`mailto:${place.contact_email}`}>
                {place.contact_email}
              </a>
            </p>
          ) : (
            ""
          )}
          {typeof(place.contact_phone) !== "undefined" ? (
            <p>
              <a href={`tel:${place.contact_phone}`}>{place.contact_phone}</a>
            </p>
          ) : (
            ""
          )}
        </InfoWindow>
      )}
    </Fragment>
  )
}

class Map extends React.Component {
  constructor(props) {
    super(props)
    let unit = _.split(this.props.content[1][1], "/")[1];
    // const userLocale = getUserLocale();
    // if(userLocale.includes("US")){
    //   unit = "mi"
    // }

    this.state = {
      places: [],
      searchedPlaces: [],
      center: MAP_CENTER,
      radius: 7000,
      results: 500,
      search: MAP_CENTER,
      expanded: false,
      unit: unit
    }

    this.location = React.createRef()
    this.radius = React.createRef()
    this.results = React.createRef()

    this.handleSearch = this.handleSearch.bind(this)
    this.handleKeydown = this.handleKeydown.bind(this)
    this.handleLocations = this.handleLocations.bind(this)
    this.sorted = this.sorted.bind(this)
    this.initSort = this.initSort.bind(this)
  }

  componentDidMount() {
    Geocode.setApiKey(process.env.GATSBY_MAP_KEY)
    fetch("/locations.json")
      .then(response => response.json())
      .then(data => {
        data.results.forEach(result => {
          result.show = false
        })
        this.setState({
          places: _.sortBy(data.results, ['country', 'state', 'city', 'name']),
          searchedPlaces: data.results,
        })
      })
  }

  sorted = (places, search, radius, results, mapsObj, map) => {
    // user search
    const searchRes = new mapsObj.LatLng(
      this.state.search[0],
      this.state.search[1]
    )
    // sort em based on distance
    const sorted = places.slice().sort((a, b) => {
      const aMap = new mapsObj.LatLng(
        a.geometry.location.lat,
        a.geometry.location.lng
      )
      const bMap = new mapsObj.LatLng(
        b.geometry.location.lat,
        b.geometry.location.lng
      )
      const aDiff = mapsObj.geometry.spherical.computeDistanceBetween(
        aMap,
        searchRes
      )
      const bDiff = mapsObj.geometry.spherical.computeDistanceBetween(
        bMap,
        searchRes
      )
      return aDiff - bDiff
    })
    // filter based on radius
    const filtered = sorted.filter(place => {
      const placeLoc = new mapsObj.LatLng(
        place.geometry.location.lat,
        place.geometry.location.lng
      )
      // convert to miles for murica
      const distance =
        mapsObj.geometry.spherical.computeDistanceBetween(searchRes, placeLoc) *
        0.000621371192

      return parseFloat(distance) < parseFloat(this.state.radius) ? place : ""
    })
    // reduce to max results
    const max =
      filtered.length > results ? filtered.slice(0, results) : filtered
    this.setState({
      searchedPlaces: max,
    })
    // fit in bounds
    const { searchedPlaces } = this.state || {}
    const bounds = new mapsObj.LatLngBounds()
    bounds.extend(
      new mapsObj.LatLng(
        searchedPlaces[0].geometry.location.lat,
        searchedPlaces[0].geometry.location.lng
      )
    )
    bounds.extend(
      new mapsObj.LatLng(
        searchedPlaces[searchedPlaces.length - 1].geometry.location.lat,
        searchedPlaces[searchedPlaces.length - 1].geometry.location.lng
      )
    )
    map.fitBounds(bounds)
  }

  handleKeydown = e => {
    if (e.key === "Enter") {
      this.handleSearch()
    }
  }

  handleLocations = e => {
    this.setState({
      expanded: !this.state.expanded,
    })
  }

  handleSearch = e => {
    const location = this.location.current.value
    const userRadius = this.state.unit === "mi"? this.radius.current.value : this.radius.current.value * 1.609;
    const results = this.results.current.value

    this.setState({
      radius: userRadius,
      results,
    })
    Geocode.fromAddress(location).then(
      response => {
        const { lat, lng } = response.results[0].geometry.location
        this.setState({
          search: [lat, lng],
        })
        const { mapsObj, map, places, radius, results, search } =
          this.state || {}
        this.sorted(places, search, radius, results, mapsObj, map)
      },
      error => {
        console.error(error)
      }
    )
  }

  // onChildClick callback can take two arguments: key and childProps
  onChildClickCallback = key => {
    this.setState(state => {
      const index = state.places.findIndex(e => e.id === key)
      if (state.places[index].show === true) {
        state.places.forEach(place => (place.show = false))
        state.places[index].show = false
      } else {
        state.places.forEach(place => (place.show = false))
        state.places[index].show = true
      }
      return { places: state.places }
    })
  }

  initSort(places, lang){
    var countries = require('i18n-iso-countries');
    let list = lang.split("-");
    let iso3166 = list[1];

    //Small dictionary to cleanup country names
    const countryTable = {
      us: "United States"
    };
    const countryLookup = (isoCode) => countryTable[isoCode] || countries.getName(iso3166, "en");

    //Using country name to pick out the countries we want
    var minusList = _.differenceBy(places, [{ "country": countryLookup(iso3166.toLowerCase())}], 'country');
    var plusList = _.differenceBy(places, minusList);

    this.setState({
      searchedPlaces: _.concat(plusList, minusList),
    })
  }

  render() {
    const {
      places,
      searchedPlaces,
      radius,
      results,
      search,
      mapsObj,
      expanded,
      unit
    } = this.state

    const handleApiLoaded = (map, maps) => {
      this.setState({
        mapsObj: maps,
        map: map,
      })
      const { mapsObj, places, radius, results, search } = this.state || {}
      this.initSort(places, this.props.lang);
    }

    // Yes this is overkill, just leaving it here for 
    // Future use if we decided to includes some options.    
    let con = []
    _.forEach(this.props.content, function(content){
      con.push(_.split(content[1], "/"));
    });
    
    //console.log(con);

    return (
      <PrintWrap>
        <ControlsWrap>
          <FormInput
            placeholder={con[0][0]}
            className="location"
            onKeyDown={this.handleKeydown}
            ref={this.location}
          />
          <FormSelect className="radius" ref={this.radius}>
            <option selected disabled value="2000">
              {con[1][0]}
            </option>
            <option value="10">{"10 " + unit}</option>
            <option value="25">{"20 " + unit}</option>
            <option value="50">{"50 " + unit}</option>
            <option value="100">{"100 " + unit}</option>
            <option value="250">{"250 " + unit}</option>
            <option value="500">{"500 " + unit}</option>
            <option value="1000">{"1000 " + unit}</option>
            <option value="2000">{"2000 " + unit}</option>
            <option value="3000">{"3000 " + unit}</option>
            <option value="5000">{"5000 " + unit}</option>
          </FormSelect>
          <FormSelect className="results" ref={this.results}>
            <option selected disabled value="500">
              {con[2][0]}
            </option>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="75">75</option>
            <option value="100">100</option>
          </FormSelect>
          <Btn as="button" onClick={() => this.handleSearch()}>
            {con[3][0]}
          </Btn>
        </ControlsWrap>
        <MapWrap>
          {!isEmpty(places) && (
            <GoogleMap
              defaultZoom={0}
              defaultCenter={MAP_CENTER}
              center={search}
              bootstrapURLKeys={{
                key: process.env.GATSBY_MAP_KEY,
                libraries: "geometry",
                language: this.props.lang
              }}
              onChildClick={this.onChildClickCallback}
              yesIWantToUseGoogleMapApiInternals={true}
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            >
              {searchedPlaces.map((place, index) => (
                <Marker
                  key={place.id}
                  lat={place.geometry.location.lat}
                  lng={place.geometry.location.lng}
                  show={place.show}
                  place={place}
                />
              ))}
            </GoogleMap>
          )}
        </MapWrap>
        <ListingWrap>
          <LocationsList>
            {searchedPlaces.map((place, index) =>
              index < 3 && expanded === false ? (
                <LocationsItem>
                  <LocationCountry>{place.country}</LocationCountry>
                  <TrialLocation>{place.name}</TrialLocation>
                  <TrialContact>
                    <p>{place.address1}</p>
                    <p>{place.address2}</p>
                    {place.country !== "United States" &&
                    place.country !== "Canada" &&
                    place.country !== "Australia" ? (
                      <Fragment>
                        <p>
                          {place.city}, {place.postal}
                        </p>
                        <br />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <p>
                          {place.city}, {place.state} {place.postal}
                        </p>
                        <br />
                      </Fragment>
                    )}
                    {place.contact_name !== "" ? (
                      <Fragment>
                        <p>{place.contact_name}</p>
                      </Fragment>
                    ) : (
                      ""
                    )}
                    {typeof(place.contact_email) !== "undefined" &&
                    typeof(place.contact_phone) !== "undefined" !== "" ? (
                      <p>
                        <a href={`mailto:${place.contact_email}`}>
                          {place.contact_email}
                        </a>{" "}
                        |{" "}
                        <a href={`tel:${place.contact_phone}`}>
                          {place.contact_phone}
                        </a>
                      </p>
                    ) : typeof(place.contact_email) !== "undefined" ? (
                      <p>
                        <a href={`tel:${place.contact_email}`}>
                          {place.contact_email}
                        </a>
                      </p>
                    ) : typeof(place.contact_phone) !== "undefined" ? (
                      <p>
                        <a href={`tel:${place.contact_phone}`}>
                          {place.contact_phone}
                        </a>
                      </p>
                    ) : (
                      ""
                    )}
                  </TrialContact>
                </LocationsItem>
              ) : expanded === true ? (
                <LocationsItem>
                  <LocationCountry>{place.country}</LocationCountry>
                  <TrialLocation>{place.name}</TrialLocation>
                  <TrialContact>
                    <p>{place.address1}</p>
                    <p>{place.address2}</p>
                    {place.country !== "United States" &&
                    place.country !== "Canada" &&
                    place.country !== "Australia" ? (
                      <Fragment>
                        <p>
                          {place.city}, {place.postal}
                        </p>
                        <br />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <p>
                          {place.city}, {place.state} {place.postal}
                        </p>
                        <br />
                      </Fragment>
                    )}
                    { typeof(place.contact_name) !== "undefined" ? (
                      <Fragment>
                        <p>{place.contact_name}</p>
                      </Fragment>
                    ) : (
                      ""
                    )}
                    { typeof(place.contact_email) !== "undefined" &&
                    place.contact_phone !== "" ? (
                      <p>
                        <a href={`mailto:${place.contact_email}`}>
                          {place.contact_email}
                        </a>{" "}
                        |{" "}
                        <a href={`tel:${place.contact_phone}`}>
                          {place.contact_phone}
                        </a>
                      </p>
                    ) : typeof(place.contact_email) !== "undefined"  ? (
                      <p>
                        <a href={`tel:${place.contact_email}`}>
                          {place.contact_email}
                        </a>
                      </p>
                    ) : typeof(place.contact_phone) !== "undefined" ? (
                      <p>
                        <a href={`tel:${place.contact_phone}`}>
                          {place.contact_phone}
                        </a>
                      </p>
                    ) : (
                      ""
                    )}
                  </TrialContact>
                </LocationsItem>
              ) : (
                ""
              )
            )}
            {searchedPlaces.length > 3 ? (
              <Btn as="button" className="long" onClick={() => this.handleLocations()}>
                {expanded === true ? con[4][0] : con[4][1]}
              </Btn>
            ) : (
              ""
            )}
          </LocationsList>
        </ListingWrap>
      </PrintWrap>
    )
  }
}

InfoWindow.propTypes = {
  place: PropTypes.shape({
    name: PropTypes.string,
    formatted_address: PropTypes.string,
  }).isRequired,
}

Marker.propTypes = {
  show: PropTypes.bool.isRequired,
  place: PropTypes.shape({
    name: PropTypes.string,
    formatted_address: PropTypes.string,
  }).isRequired,
}

export default Map
