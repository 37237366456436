import styled from "@emotion/styled"

export const Enlarge = styled.h3`
  color: ${props => props.theme.colors.copy};
  font-size: ${props => props.theme.fontSizes.sm};
  font-family: ${props => props.theme.fonts.copy};
  font-weight:  ${props => props.theme.weights.reg};
  font-style: italic;
  line-height: 1.5;
  margin: 0 0 -1rem;
`;

export const Schema = styled.img`
    display: block;
    max-width: 600px;
    width: 100%;
    margin: 2rem 0;
    cursor: pointer;
    &.is-modal {
      max-width: 100%;
    }
`;

export const ModalBody = styled.section`
  background: ${props => props.theme.colors.white};
  position: relative;
  max-width: 1300px;
  width: 95%;
  padding: 3rem;
  &.confirmation {
    max-width: 500px;
    padding: 2rem;
  }
`;

export const ModalClose = styled.button`
  background: transparent;
  border: none;
  appearance: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  right: -.75rem;
  top: -.75rem;
  z-index: 99999;
  &:focus {
    outline: 0;
  }
`;