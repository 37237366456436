import _ from "lodash"
import React, { Fragment } from "react"
import { Form, Field } from "react-final-form"

import {
  FormBlock,
  FormField,
  FormRow,
  FormInput,
  FormRadio,
  FormSelect,
  FormLabel,
  ErrorMsg,
  Checkbox,
  Radio,
  Btn,
  Spinner,
  ModalBody,
  ModalClose,  
  SectionSubtitle,
  SectionCopy,
} from "./styles"

import { PrintWrap } from '../styled'
import StyledModal from "../styledModal"

import Check from "../../images/modal-check.inline.svg"
import Close from "../../images/modal-close.inline.svg"

class InquiryForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submitIsOpen: false,
    }

    this.openSubmit = this.openSubmit.bind(this)
    this.closeSubmit = this.closeSubmit.bind(this)
  }

  returnOptions(con){
    let opt = [];

    _.forEach(con, function(c){
      opt.push(
        <option value={c}>
          {c}
        </option>
      )
    })

    return opt;
  }

  openSubmit() {
    this.setState({ submitIsOpen: true })
  }

  closeSubmit() {
    this.setState({ submitIsOpen: false })
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 150)
  }

  render(){
    const { submitIsOpen } = this.state;

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    const encode = (data) => {
      return Object.keys(data)
          .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
          .join("&");
    }

    const onSubmit = async (values) => {
      await sleep(300)

      //console.log(values);

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "Inquiry", ...values })
      })
        .then(() => 
          this.setState({
            submitIsOpen: true,
          }))
        .catch(error => alert(error));
      
    }

    const required = value => (value ? undefined : con[1][1])
    const invalid = (forbidden, error) => value =>
      !value
        .toString()
        .toLowerCase()
        .includes(forbidden.toString().toLowerCase())
        ? null
        : error
    const minLength = (min, error) => value =>
      value.length >= min ? undefined : error
    const maxLength = max => value =>
      value === undefined || value.length <= max
        ? undefined
        : `Maximum length: ${max}`
    const match = (match, error) => value =>
      value === match ? undefined : error
    const regex = (pattern, error) => value =>
      value.match(pattern) ? null : error
    const dob = value => {
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()
      const birthDate = new Date(value)
      const birthYear = birthDate.getFullYear()
      return currentYear - birthYear >= 18
        ? undefined
        : con[4][4]
    }
    const past = value => {
      const currentDate = new Date()
      const selectedDate = new Date(value)
      return currentDate >= selectedDate
        ? undefined
        : "Please select a valid date"
    }

    const composeValidators = (...validators) => value =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined
      )

    let con = []
    _.forEach(this.props.content, function(content){
      con.push(_.split(content[1], "/"));
    });
    let extraAffirm = false;
    if (con[6][0] && con[6][0].split('[n]').length > 1) {
      let conArray = con[6][0].split('[n]');
      con[6][0] = conArray[0];
      extraAffirm = conArray[1];
    }
    //Detecting if it's a build, so that all of the form elements can be shown
    let isBuild = typeof window === 'undefined';

    return(
      <PrintWrap>
      <Form 
        onSubmit={onSubmit}
        initialValues={{
          email: "",
          firstName: "",
          postcode: "",
          country: "0",
          age: false,
          collect: false,
          contact: false,
        }}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          validating,
          values,
          submitError,
          hasValidationErrors,
        }) => (
          <form data-netlify="true" onSubmit={handleSubmit} name="Inquiry">
            {validating && <Spinner />}
            <FormBlock>
              <FormRow>
                <FormField>
                  <Field
                    name="country"
                    component="select"
                    validate={composeValidators(
                      required
                    )}
                  >
                    {({ input, meta }) => (
                      <Fragment>
                        <FormLabel
                          className="is-hidden"
                          htmlFor="country"
                        >
                          {con[0][1]}
                        </FormLabel>
                        <FormSelect {...input} name="country">
                          <option disabled value="0">
                            {con[0][0]+"*"}
                          </option>
                          {this.returnOptions(con[0].slice(1, (con[0].length - 1)))}
                        </FormSelect>
                        {meta.error && meta.touched && (
                          <ErrorMsg>{meta.error}</ErrorMsg>
                        )}
                      </Fragment>
                    )}
                  </Field>
                </FormField>
              </FormRow>
            </FormBlock>
            {(values.country !== "0" || isBuild)? (
              <Fragment>
                <FormRow>
                  <FormField className="name">
                    <Field
                      name="firstName"
                      validate={composeValidators(
                        required,
                        maxLength(120)
                      )}
                    >
                      {({ input, meta }) => (
                        <Fragment>
                          <FormLabel
                            className="is-hidden"
                            htmlFor="firstName"
                          >
                            {con[1][0]}
                          </FormLabel>
                          <FormInput
                            name="firstName"
                            placeholder={con[1][0]+"*"}
                            {...input}
                          />
                          {meta.error && meta.touched && (
                            <ErrorMsg>{meta.error}</ErrorMsg>
                          )}
                        </Fragment>
                      )}
                    </Field>
                  </FormField>
                  <FormField className="email">
                    <Field
                      name="email"
                      validate={composeValidators(
                        required,
                        maxLength(128),
                        regex(
                          /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                          con[2][2]
                        )
                      )}
                    >
                      {({ input, meta }) => (
                        <Fragment>
                          <FormLabel
                            className="is-hidden"
                            htmlFor="email"
                          >
                            {con[2][1]}
                          </FormLabel>
                          <FormInput
                            name="email"
                            type="email"
                            placeholder={con[2][0]+"*"}
                            {...input}
                          />
                          {meta.error && meta.touched && (
                            <ErrorMsg>{meta.error}</ErrorMsg>
                          )}
                        </Fragment>
                      )}
                    </Field>
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField className="postcode">
                    <Field
                      name="postcode"
                      validate={composeValidators(
                        required,
                        //match 5 or 5-4 zip and 4 zip(for HU)
                        regex(
                          /^\d{5}(?:[-\s]\d{4})?$|^\d{4}$/,
                          con[3][1]
                        )
                      )}
                    >
                      {({ input, meta }) => (
                        <Fragment>
                          <FormLabel
                            className="is-hidden"
                            htmlFor="postcode"
                          >
                            {con[3][0]}
                          </FormLabel>
                          <FormInput
                            name="postcode"
                            placeholder={con[3][0]+"*"}
                            {...input}
                          />
                          {meta.error && meta.touched && (
                            <ErrorMsg>{meta.error}</ErrorMsg>
                          )}
                        </Fragment>
                      )}
                    </Field>
                  </FormField>
                </FormRow>
                <FormLabel>
                  {con[4][0]}
                </FormLabel>
                <FormRow css={Radio} className="is-radiogroup">
                  <Fragment>
                    <FormField className="is-radio is-short">
                      <Field
                        name="age"
                        type="radio"
                        id="yes"
                        value="yes"
                      >
                        {({ input }) => (
                          <Fragment>
                            <FormInput
                              name="age"
                              type="radio"
                              value="yes"
                              id="yes"
                              css={FormRadio}
                              className="is-radio"
                              {...input}
                            />
                            <FormLabel
                              name="age"
                              className="is-hidden"
                              htmlFor="yes"
                            >
                              Age
                            </FormLabel>
                            <FormLabel
                              name="age"
                              className="is-radio is-info"
                              htmlFor="yes"
                            >
                              {con[4][1]}
                            </FormLabel>
                          </Fragment>
                        )}
                      </Field>
                    </FormField>
                    <FormField className="is-radio is-short">
                      <Field name="age" type="radio" id="no" value="no">
                        {({ input }) => (
                          <Fragment>
                            <FormInput
                             name="age"
                              type="radio"
                              value="no"
                              id="no"
                              css={FormRadio}
                              className="is-radio"
                              {...input}
                            />
                            <FormLabel
                              name="age"
                              className="is-radio is-info"
                              htmlFor="no"
                            >
                              {con[4][2]}
                            </FormLabel>
                          </Fragment>
                        )}
                      </Field>
                    </FormField>
                  </Fragment>
                </FormRow>
                {values.age === "no" ? (
                  <ErrorMsg className="radio-error">
                    {con[4][3]}
                  </ErrorMsg>
                ) : (
                  ""
                )}
                <FormLabel>
                  {con[5][0] + "*"}
                </FormLabel>
                <FormRow className="is-start is-check" css={Checkbox}>
                  <FormField className="is-check is-full">
                    <Field
                      name="contact"
                      id="contact"
                      type="checkbox"
                      validate={required}
                    >
                      {({ input, meta }) => (
                        <Fragment>
                          <FormInput
                            name="contact"
                            type="checkbox"
                            id="contact"
                            {...input}
                          />
                          <FormLabel
                            name="contact"
                            className="is-hidden"
                            htmlFor="contact"
                          >
                            Contact
                          </FormLabel>
                          <FormLabel
                            name="contact"
                            className="is-full"
                            htmlFor="contact"
                          >
                            {con[5][1]}
                          </FormLabel>
                          {meta.error && meta.touched && (
                            <ErrorMsg>{meta.error}</ErrorMsg>
                          )}
                        </Fragment>
                      )}
                    </Field>
                  </FormField>
                </FormRow>
                <FormLabel>
                  {con[6][0]+"*"}
                </FormLabel>
                {extraAffirm !== false && <FormLabel>{extraAffirm+'*'}</FormLabel>}
                <FormRow className="is-start is-check" css={Checkbox}>
                  <FormField className="is-check is-full">
                    <Field
                      name="collect"
                      id="collect"
                      type="checkbox"
                      validate={required}
                    >
                      {({ input, meta }) => (
                        <Fragment>
                          <FormInput
                            name="collect"
                            type="checkbox"
                            id="collect"
                            {...input}
                          />
                          <FormLabel
                            name="collect"
                            className="is-hidden"
                            htmlFor="collect"
                          >
                            Collect
                          </FormLabel>
                          <FormLabel
                            name="collect"
                            className="is-full"
                            htmlFor="collect"
                          >
                           {con[6][1]}
                          </FormLabel>
                          {meta.error && meta.touched && (
                            <ErrorMsg>{meta.error}</ErrorMsg>
                          )}
                        </Fragment>
                      )}
                    </Field>
                  </FormField>
                </FormRow>

                <Btn
                  as="button"
                  className="big-pad"
                  type="submit"
                  disabled={
                    submitting ||
                    validating ||
                    hasValidationErrors ||
                    values.age === ""
                  }
                >
                  {con[7][0]}
                </Btn>
                {submitError && <ErrorMsg>{submitError}</ErrorMsg>}
              </Fragment>
            ) : (
              ""
            )}
          </form>
        )}
      />
        <StyledModal
          active={true}
          modalIsOpen={submitIsOpen}
          closeModal={this.closeSubmit}
        >
          <ModalBody className="confirmation">
            <ModalClose onClick={this.closeSubmit}>
              <Close />
            </ModalClose>
            <Check className="is-centered" />
            <SectionSubtitle className="centered">
              {con[8][0]}
            </SectionSubtitle>
            <SectionCopy className="centered">
             {con[8][1]}
            </SectionCopy>
          </ModalBody>
        </StyledModal>
      </PrintWrap>
    );
  }
}

export default InquiryForm