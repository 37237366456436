import _ from "lodash"
import React from "react"
import { Link } from "react-scroll"
import InquiryForm from "../components/inquiryForm"

import { Section,
  SectionCopy,
  SectionTitle,
  SectionSubtitle,
  btn, 
  List,
  ListItem,
  Enlarge,
  Schema,
  References,
  PrintSidebarWrap,
  PrintHeaderWrap,
  PrintHeaderLogo
} from "../components/content/styles";
import Map from "../components/map";
import CustomAccordion from "../components/customAccordion";
import Teams from "../components/teams";
import Sidebar from "../components/sidebar";
import Picture from "../components/Picture";
import { HeaderWrap, HeaderShim, HeaderLogo, HeaderTitle, HeaderSub } from "../components/header/styles";
import LightLogo from "../images/logo-light.svg"
import { Wrapper } from "../components/styled"

export const returnSections = (sections, lang, page, openM) => {
  let items = [];
  _.forEach(sections, function(section){
    let content = _.find(section.Languages, {"lang": lang});
    let body = _.split(content.section.content, "\n");

    if(content.section.content.includes("[Accordions]")){
      let rawFAQs = [...content.section.content.matchAll(/\<([\s\S]*?)\>/g)];

      items.push(
        <Section id={section.section_title} key={section.section_title}>
          <SectionTitle>
            {content.section.title}
          </SectionTitle>
          <CustomAccordion
            content={rawFAQs}
          />
        </Section>
      )
    }else if(section.section_title === "references"){
      let refs = [];
      let lines = _.split(content.section.content, "\n");
      _.forEach(lines, function(line){
        refs.push(
          <li dangerouslySetInnerHTML={{__html:italics(line.substring(line.indexOf(" ") + 1))}}/>
        )
      })
      
      items.push(
        <Section className="no-border" id={section.section_title} key={section.section_title}>
          <SectionTitle>
            {content.section.title}
          </SectionTitle>
          <References>
            {refs}
          </References>
        </Section>
      )
    }else if(!(
        section.section_title === "popup" ||
        section.section_title === "sidebar" ||
        section.section_title === "footer" || 
        section.section_title === "header"
      )){
        let paragraphs = [];
      _.forEach(body, function(paragraph){
        if(paragraph.includes("]<")){
          // Special Component needs to be handled
          let sectionType = /\[(.*?)[\]\)]/.exec(paragraph)[1];
          let content = [...paragraph.matchAll(/\<(.*?)\>/g)];

          //console.log(sectionType)
          //console.log(content)
          switch(sectionType){
            case "Inquiry Submission Form":
              paragraphs.push(
                <InquiryForm
                  content={content}
                />
              )
              break;
            case "Locations Map":
              paragraphs.push(
                <Map
                  content={content}
                  lang={lang}
                />
              );
              break;
            case "Link Button":
              let rawText = content[0].input;
              let text = /\<(.*?)[\>\)]/.exec(rawText)[1];
              let link = /\((.*?)\)/.exec(rawText)[1].substring(1);
              
              paragraphs.push(
                <Link
                  css={btn}
                  to={link}
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-50}
                  className={link.includes("trial")? "print-none": null}
                >
                  {text}
                </Link>
              )
              break;
            case "Committee":
              paragraphs.push(
                <Teams />
              )
              break;
            default:
              console.log(`Sorry, ${sectionType} is not supported.`);
              break;
          }
        }else if(paragraph.includes("###")){
          // Usually subtitle?
          //console.log("st")
        }else if(paragraph.includes("![") && paragraph.includes("](")){
          paragraphs.push(
            <Picture 
              image={/\((.*?)\)/.exec(paragraph)[1]}
            />
          )
        }else if(paragraph.indexOf("*") === 0 && !paragraph.includes("**")){
          // It's a list (Yes this does create multiple single itemed ul probably should be fixed at some point)
          let type = _.toLower(content.section.list_type)
          paragraphs.push(
            <List key={paragraph.substring(2)}>
              <ListItem className={type? type: "dot"}>
                {paragraph.substring(2)}
              </ListItem>
            </List>
          )
        }
        else if(paragraph){
          let copytext = paragraph;
          // Bit of brute force here
          if(paragraph.includes("](")){
            //console.log(paragraph);
            copytext = toLinks(paragraph);
          }
          if(paragraph.includes("**")){
            paragraphs.push(
              <SectionCopy key={copytext}>
                <strong>
                  {copytext.substring(2,copytext.length-2)}
                </strong>
              </SectionCopy>
            );
          }else if(paragraph.includes("##")){
            paragraphs.push(
              <SectionSubtitle className="is-sub">
                {copytext.substring(3)}
              </SectionSubtitle>
            )
          }else{
            //console.log(copytext)
            paragraphs.push(
              <SectionCopy key={copytext} dangerouslySetInnerHTML={{__html: copytext}}/>
            );
          }
        }
      });
      
      if(section.section_title === "intro" && page === "home"){
        items.push(
          <>
            <PrintHeaderWrap>
              <HeaderWrap role="banner">
                <HeaderShim>
                  <Wrapper>
                    <PrintHeaderLogo>
                      <HeaderLogo src={LightLogo} />
                      <div class="tag">
                        {page === "home"? "Non-HCP": null}
                      </div>
                    </PrintHeaderLogo>
                    {returnSubTitles(sections, lang)}
                  </Wrapper>
                </HeaderShim>
              </HeaderWrap>
            </PrintHeaderWrap>
            <Section className="small" id={section.section_title} key={section.section_title}>
              <SectionTitle>
                {content.section.title}
              </SectionTitle>
              {paragraphs}
            </Section>
            <PrintSidebarWrap>
              <Sidebar page={page} lang={lang} content={_.find(sections, {"section_title": "sidebar"})} mobile={false} print={true}/>
            </PrintSidebarWrap>
          </>
        )
      }else if(section.section_title==="about"){
        items.push(
          <>
            {
            page === "hcp"? 
              <PrintHeaderWrap>
                <HeaderWrap role="banner">
                  <HeaderShim>
                    <Wrapper>
                      <PrintHeaderLogo>
                        <HeaderLogo src={LightLogo} />
                        <div class="tag">
                          {page === "hcp"? "HCP": null}
                        </div>
                      </PrintHeaderLogo>
                      {returnSubTitles(sections, lang)}
                    </Wrapper>
                  </HeaderShim>
                </HeaderWrap>
              </PrintHeaderWrap>: 
              null
            }
            <Section id={section.section_title} key={section.section_title}>
              <SectionTitle>
                {content.section.title}
              </SectionTitle>
              {paragraphs}
            </Section>
            <Sidebar page={page} lang={lang} content={_.find(sections, {"section_title": "sidebar"})} mobile={true}/>
            {
            page === "hcp"? 
              <PrintSidebarWrap>
                <Sidebar page={page} lang={lang} content={_.find(sections, {"section_title": "sidebar"})} mobile={false} print={true}/>
              </PrintSidebarWrap>:
              null
            }
          </>
        )
      }else{
        items.push(
          <Section id={section.section_title} key={section.section_title}>
            <SectionTitle>
              {content.section.title}
            </SectionTitle>
            {paragraphs}
          </Section>
        )
      }
    }
  });

  //console.log(items)
  return items;
}

const italics = (t) =>{
  let p = "";
  
  do{
    let textRex = new RegExp(/\*(.*?)\*/);
    let text = textRex.exec(t);
    let sIndex = text.index;
    let eIndex = sIndex + text[1].length + 2;

    p += " " + t.substring(0, sIndex);
    p += "<em>" + text[1] + "</em>";

    // cut up to where we replaced
    t = t.substring(eIndex);
  }while(false);

  p += t;

  return p;
}

export const toLinks = (p) => {
  let fP = "<p>"

  do{
    // console.log(p)
    let textRex = new RegExp(/\[(.*?)[\]\)]/);
    let linkRex = new RegExp(/\]\((.*?)\)/);
    let text = textRex.exec(p);
    let link = linkRex.exec(p);
    let sIndex = text.index
    let eIndex = link.index + link[1].length + 2;

    // Due to having to use dangerouslySetHTML for the superscripts, this is no longer viable
    if(link[0].includes("#")){
      fP += p.substring(0, sIndex);
      fP += "<a href=" + link[1] + ">" + text[1] + "</a>";
    }else{
      fP += p.substring(0, sIndex);
      fP += "<a target=\"_blank\" href=" + link[1] + ">" + text[1] + "</a>";
    }
    // cut up to where we replaced
    p = p.substring(eIndex + 1);
  }while(p.includes("]("));


  fP += p + "</p>";

  // console.log(fP);

  return fP;
}

export const returnSubTitles = (sections, lang) => {
  let headerC = _.find(_.find(sections, {"section_title": "header"}).Languages, {"lang": lang});

  let rawText = _.split(headerC.section.content, "\n");
  rawText = _.drop(rawText)

  //console.log(rawText);

  let subs = [];
  _.forEach(rawText, function(text){
    let index = text.indexOf("###");
    if(index >= 0){
      subs.push(<HeaderTitle>{text.substring(3)}</HeaderTitle>);
    }else{
      subs.push(<HeaderTitle>{text}</HeaderTitle>);
    }
  })

  return subs;
}