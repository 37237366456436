import PropTypes from "prop-types"
import React, { Fragment } from "react"
import { Form, Field } from "react-final-form"
import { Link, animateScroll as scroll } from "react-scroll"
import _ from "lodash"
import { returnSections } from "../../util/util"

import {
  SectionWrap,
} from "./styles"

import { Wrapper } from "../styled"

class Content extends React.Component {

  componentDidMount() {
    this.setState({ width: window.innerWidth })
    window.addEventListener("resize", this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  render() {
    const { nav, sections, lang, page, dir } = this.props

    return (
      <Fragment>
        <Wrapper className={dir === "rtl"? "rtl" : ""}>
          <SectionWrap>
            { returnSections(sections, lang, page) }
          </SectionWrap>
        </Wrapper>
      </Fragment>
    )
  }
}

export default Content

Content.propTypes = {
  style: PropTypes.object,
  theme: PropTypes.object,
}
