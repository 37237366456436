import PropTypes from "prop-types"
import React from "react"

import {
  Schema,
  Enlarge,
  ModalBody,
  ModalClose,  
} from "./styles"

import StyledModal from "../styledModal"
import Close from "../../images/modal-close.inline.svg"

class Picture extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      submitIsOpen: false,
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal() {
    this.setState({ modalIsOpen: true })
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  render(){
    const { modalIsOpen } = this.state;
    const { image } = this.props;

    let text, imageURL;

    if(image.includes("\"")){
      let textRex = new RegExp(/\"(.*?)\"/);
      let textEx = textRex.exec(image);
      
      text = textEx[1];
      imageURL = image.substring(0,textEx.index - 1);
    }else{
      text = "Please click to enlarge picture.";
      imageURL = image;
    }

    return(
      <>
        <Enlarge>{text}</Enlarge>
        <Schema onClick={this.openModal} src={imageURL} />
        <StyledModal
          active={true}
          modalIsOpen={modalIsOpen}
          closeModal={this.closeModal}
        >
          <ModalBody>
            <ModalClose onClick={this.closeModal}>
              <Close />
            </ModalClose>
            <Schema className="is-modal" src={imageURL} />
          </ModalBody>
        </StyledModal>
      </>
    )
  }

}

export default Picture

Picture.propTypes = {
  style: PropTypes.object,
  theme: PropTypes.object,
}