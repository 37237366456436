import PropTypes from "prop-types"
import React from "react"
import _ from "lodash"

import {
  SidebarAside,
  SidebarInfo,
  SidebarHeader,
  SidebarCopy,
  ShareBtns,
  shareIcon
} from "./styles"

import Fb from "../../images/fb.inline.svg"
import Twitter from "../../images/twitter.inline.svg"
import Email from "../../images/email.inline.svg"
import { toLinks } from "../../util/util"

class Sidebar extends React.Component {

  returnSideBarContent(){
    let items = []
    let socialLinks = "";
    let contents = _.split(_.find(this.props.content.Languages, {"lang": this.props.lang}).section.content, "\n");

    _.forEach(contents, function(content){
      if(content.includes("###")){
        if(content.includes("Share")){
          items.push(
            <SidebarHeader className="print-none">
              {content.substring(3)}
            </SidebarHeader>
          )
        }else{
          items.push(
            <SidebarHeader>
              {content.substring(3)}
            </SidebarHeader>
          )
        }
      }else if(_.toLower(content).includes("[facebook]") || _.toLower(content).includes("[twitter]")){
        socialLinks += content+ "\n";
      }else if(_.toLower(content).includes("[email]")){
        socialLinks += content+ "\n";
        let linkRex = new RegExp(/\((.*?)\)\n|\)$/g);
        let links = [...socialLinks.matchAll(linkRex)];
        
        items.push(
          <div className="print-none">
            <ShareBtns>
              <a target="_blank" href={links[0][1]}>
                <Fb css={shareIcon} />
              </a>
              <a target="_blank" href={links[1][1]}>
                <Twitter css={shareIcon} />
              </a>
              <a target="_blank" href={links[2][1]}>
                <Email css={shareIcon} />
              </a>
            </ShareBtns>
            <br/>
          </div>
        );
      }else if(content){
        let t;
        if(content.includes("](")){
          t = toLinks(content);
        }else{
          t = content;
        }
        items.push(
          <SidebarCopy
            dangerouslySetInnerHTML={{__html: t}}
          />
        )
      }
    })

    

    return items;
  }
  render() {
    const { page } = this.props
    return (
      <SidebarAside className={this.props.mobile? "mobile": this.props.print? "print" : null}>
        <SidebarInfo className={this.props.mobile? "mobile": this.props.print? "print" : null}>
          {this.returnSideBarContent()}
        </SidebarInfo>
      </SidebarAside>
    )
  }
}

export default Sidebar

Sidebar.propTypes = {
  style: PropTypes.object,
  theme: PropTypes.object,
}
