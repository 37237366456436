import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { mq, theme } from '../../util/theme'

import Acc from '../../images/carat-down.svg'

import MobAcc from '../../images/carat-mob.svg'

export const AccPanel = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
`;

export const accStyles = css`
  width: 100%;
  margin: auto;
  .accordion__button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: ${theme.colors.copy};
    font-size: ${theme.fontSizes.h3};
    font-family: ${theme.fonts.heading};
    font-weight:  ${theme.weights.bold};
    margin: 0;
    padding: 1rem 3rem 1rem 0;
    border: none;
    border-top: 1px solid ${theme.colors.gray};
    transition: all .15s ease-in-out;
    background: ${theme.colors.white};
    line-height: 1.25;
    &:hover {
      outline: none;
    }
    &:focus {
      outline: none;
    }
    &:before {
      display: none;
    }
    &:after {
      content: '';
      background: url(${Acc}) no-repeat center/cover;
      display: block;
      z-index: 1;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 1rem;
      margin: auto;
      height: .5rem;
      width: 1rem;
      transform-origin: center center;
      transition: transform .2s ease-in-out;
    }
    &[aria-expanded="true"] {
      //background: ${theme.colors.blue};
      //color: ${theme.colors.white};
      //border-bottom: 1px solid ${theme.colors.white};

      &:after {
       transform: rotate(180deg);
      }
    }
    .acc-img {
      display: block;
      margin-right: 1.5rem;
    }
  }
  .accordion__item {
    border: none;
    &:last-of-type {
        border-bottom: 1px solid ${theme.colors.gray};
    }
  }
  .accordion__panel {
    transition: all .3s ease;
    display: block;
    overflow: hidden;
    padding: 0;
    font-size: ${props => props.theme.fontSizes.h3};
    font-family: ${props => props.theme.fonts.copy};
    font-weight:  ${props => props.theme.weights.reg};
    line-height: 1.5;
    margin: 0 0 1.5rem;
    a {
       color: ${theme.colors.copy};
       text-decoration: underline;
       &:hover {
         color: ${theme.colors.blue};
       }
    }
    p {
      margin: 0 0 1rem;
    }
    ${mq[2]} {
      background: ${theme.colors.white};
      color: ${theme.colors.copy};
    }
    &--collapsed {
      max-height: 0;
    }
    &--expanded {
      max-height: 500px;
      padding: 0 1.5rem 1.5rem;
      ${mq[2]} {
        padding: 0 1.5rem 3rem;
      }
    }
  }
  .accordion__content{
    @media print{
      height: auto !important;
    }
  }
`;
