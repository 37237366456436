import styled from "@emotion/styled"
import { mq } from '../../util/theme'


export const TeamWrap = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: 3rem;
  ${mq[1]} {
    justify-content: flex-start;
  }
`

export const Team = styled.div`
  max-width: 130px;
  width: 100%;
  margin: 0 1rem 3rem 0;
  &:nth-of-type(2n) {
    margin-right: 0;
  }
  ${mq[1]} {
    &:nth-of-type(2n) {
      margin-right: 1rem;
    }
    &:nth-of-type(4n) {
      margin-right: 0;
    }
  }
  p {
    color: ${props => props.theme.colors.copy};
    font-size: ${props => props.theme.fontSizes.tiny};
    margin: 0 0 0.25rem;
  }
`

export const TeamImg = styled.img`
  max-width: 120px;
  display: block;
  width: 100%;
  margin: 0 0 0.5rem;
  border-radius: 5px;
  ${mq[1]} {
    max-width: 130px;
  }
`