import PropTypes from "prop-types"
import React from "react"
import _ from "lodash"

import {
  TeamWrap,
  TeamImg,
  Team,
} from "./styles"

class Teams extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      members: []
    }
  }

  componentDidMount() {
    fetch("/members.json")
      .then(response => response.json())
      .then(data => {
        this.setState({
          members: data.Members
        })
      })
  }

  renderMembers(){
    let render = [];

    _.forEach(this.state.members, function(member){
      render.push(
        <Team>
          <TeamImg src={member.pic} alt={member.name}/>
          <p><strong>{member.name}</strong></p>
          <p>{member.orgs}</p>
        </Team>
      )
    })

    return render;
  }
  render(){
    return(
      <TeamWrap>
        {this.renderMembers()}
    </TeamWrap>
    )
  }
}

export default Teams

Teams.propTypes = {
  style: PropTypes.object,
  theme: PropTypes.object,
}
