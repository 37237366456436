// import PropTypes from "prop-types"
import React, { Fragment } from "react"

import {
  Accordion,
  AccordionItem,
  AccordionItemState,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import _ from "lodash";
import { toLinks } from "../../util/util";

import posed from 'react-pose';

import 'react-accessible-accordion/dist/fancy-example.css';

import {
  AccPanel,
  accStyles
} from './styles'

const AccItem = posed.div({
  closed: { height: 0, overflow: 'hidden' },
  open: { height: 'auto' }
});

class CustomAccordion extends React.Component {
  createAccordionItems(){
    let items = [];

    _.forEach(this.props.content, function(content){
      let split = _.split(content[0], "|");
      let q = split[0];
      let rawA = _.remove(_.split(split[1].substring(0,split[1].length-1), "\n"), function(a){
        return a
      });

      let a = "";
      _.forEach(rawA, function(t){
        if(t.includes("](")){
          a += toLinks(t);
        }else{
          a += "<p>" + t + "</p>";
        }
      })

      items.push(
        {
          question: q.substring(1),
          answer: a
        }
      )
    })

    return items;
  }
  returnAccordions(items){
    let acc = [];

    _.forEach(items, function(item){
      const {
        question,
        answer
      } = item;

      acc.push(
        <AccordionItem>
          <AccordionItemState>
            {({ expanded }) => (
              <Fragment>
                <AccordionItemHeading>
                  <AccordionItemButton className="acc-btn-over accordion__button">
                    {question}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccItem pose={expanded === true ? 'open' : 'closed'} className="accordion__content">
                  <AccordionItemPanel
                    className={'accordion__panel'}
                    dangerouslySetInnerHTML={{ __html: answer }}
                  />
                </AccItem>
              </Fragment>
            )}
          </AccordionItemState>
        </AccordionItem>
      )
    })

    return acc;
  }

  render() {
    let items = this.createAccordionItems();

    return (
        <AccPanel>
          <Accordion css={accStyles} allowZeroExpanded={true}>
            { this.returnAccordions(items) }
          </Accordion>
        </AccPanel>
    )
  }
}

export default CustomAccordion;